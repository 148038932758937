import { IconAllo, IconBlockpack, IconDappflow, IconPeraExplorer } from './ExplorerIcon.icons'
import { Explorer } from 'src/data/explorers'
import { useExplorerStore } from 'store/index'

interface ExplorerIconProps {
  className?: string
  explorer?: Explorer
}

export default function ExplorerIcon({ className = '', explorer }: ExplorerIconProps) {
  const { selectedExplorer: _selectedExplorer } = useExplorerStore((state) => state)

  const selectedExplorer = explorer || _selectedExplorer

  const renderIcon = () => {
    switch (selectedExplorer) {
      case 'Allo':
        return <IconAllo className={className} />
      case 'Blockpack':
        return <IconBlockpack className={className} />
      case 'Dappflow':
        return <IconDappflow className={className} />
      case 'PeraExplorer':
        return <IconPeraExplorer className={className} />
      default:
        return null
    }
  }

  return renderIcon()
}
