import { Dialog, Disclosure, Transition } from '@headlessui/react'
import Link from 'next/link'
import { Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { AiFillBank } from 'react-icons/ai'
import { HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineUserCircle } from 'react-icons/hi'
import { HiArrowUpRight, HiOutlineXMark } from 'react-icons/hi2'
import { TbRectangle } from 'react-icons/tb'
import AssetMedia from 'components/AssetMedia'
import ExplorerIcon from 'components/ExplorerIcon'
import UserThumbnail from 'components/UserThumbnail'
import WalletThumbnail from 'components/WalletThumbnail'
import { IconPeraExplorer } from '../Assets.icons'
import TokenImage from './TokenImage'
import { useExplorerStore } from 'store/index'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { formatAssetBalance } from 'helpers/utilities'
import type { Asset, AssetAmount } from 'types/assets'
import type { NfdRecord } from 'api/api-client'

interface AssetDetailsProps {
  open: boolean
  setOpen: (open: boolean) => void
  nfd: NfdRecord
  asset: Asset
  isNft: boolean
  isAccountsOpen: boolean
  totalAmount: number
  handleClickSetField: (field: 'avatar' | 'banner') => void
  handleClickSendAsset: () => void
  handleClickMoveToVault: () => Promise<void>
  handleClickMoveToDepositAccount: () => Promise<void>
  handleClickFilterAccount: (account: string) => void
  canSetField: boolean
  canSendAsset: boolean
  canMoveToVault: boolean
  canMoveToDepositAccount: boolean
}

export default function AssetDetails({
  open,
  setOpen,
  nfd,
  asset,
  isNft,
  isAccountsOpen,
  totalAmount,
  handleClickSetField,
  handleClickSendAsset,
  handleClickMoveToVault,
  handleClickMoveToDepositAccount,
  handleClickFilterAccount,
  canSetField,
  canSendAsset,
  canMoveToVault,
  canMoveToDepositAccount
}: AssetDetailsProps) {
  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByAssetId = useExplorerStore((state) => state.lookupByAssetId)

  const renderAccounts = (amounts: AssetAmount[]) => {
    if (amounts.length === 1) {
      const account = asset.amounts[0].account

      return (
        <div className="flex justify-between py-3 text-sm font-medium">
          <dt className="text-gray-500">Account</dt>
          <dd className="text-gray-900 dark:text-gray-100 flex items-center space-x-2">
            <button
              type="button"
              className="group min-w-0 flex items-center"
              onClick={() => handleClickFilterAccount(account)}
            >
              {account === nfd.nfdAccount ? (
                <span className="inline-flex items-center">
                  <AiFillBank className="mr-2 h-5 w-5 text-gray-900 dark:text-gray-400 group-hover:text-brand-500 dark:group-hover:text-brand-500" />
                  <span className="font-medium dark:text-gray-400 group-hover:text-brand-500 dark:group-hover:text-brand-500">
                    {nfd.name}
                  </span>
                </span>
              ) : (
                <WalletThumbnail
                  address={account}
                  link={false}
                  className="group-hover:text-brand-500 dark:group-hover:text-brand-500"
                />
              )}
            </button>
          </dd>
        </div>
      )
    }

    return (
      <Disclosure as="div" defaultOpen={isAccountsOpen}>
        {({ open }) => (
          <>
            <dt>
              <Disclosure.Button className="w-full flex justify-between items-center py-3 text-sm font-medium">
                <span className="text-gray-500">Accounts {`(${amounts.length})`}</span>
                <span className="ml-6 flex items-center text-gray-600 dark:text-gray-400">
                  {open ? (
                    <HiOutlineChevronUp className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <HiOutlineChevronDown className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel as="dd" className="mb-2">
              {amounts.map((assetAmount) => (
                <div
                  key={assetAmount.account}
                  className="flex items-center justify-between py-3 text-sm font-medium"
                >
                  <div className="flex items-center text-gray-900 dark:text-gray-100">
                    <button
                      type="button"
                      className="group min-w-0 flex items-center"
                      onClick={() => handleClickFilterAccount(assetAmount.account)}
                    >
                      {assetAmount.account === nfd.nfdAccount ? (
                        <span className="inline-flex items-center">
                          <AiFillBank className="mr-2 h-5 w-5 text-gray-900 dark:text-gray-400 group-hover:text-brand-500 dark:group-hover:text-brand-500" />
                          <span className="font-medium dark:text-gray-400 group-hover:text-brand-500 dark:group-hover:text-brand-500">
                            {nfd.name}
                          </span>
                        </span>
                      ) : (
                        <WalletThumbnail
                          address={assetAmount.account}
                          link={false}
                          className="group-hover:text-brand-500 dark:group-hover:text-brand-500"
                        />
                      )}
                    </button>
                  </div>
                  <div className="text-gray-900 font-mono dark:text-gray-100">
                    {formatAssetBalance(assetAmount.amount, asset.decimals, true, true, 14)}
                  </div>
                </div>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  }

  const renderInfo = () => {
    return (
      <div>
        <dl className="-mt-1 divide-y divide-gray-200 border-b border-t border-gray-200 dark:divide-gray-800 dark:border-gray-800">
          <div className="flex justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Quantity</dt>
            <dd className="text-gray-900 font-mono dark:text-gray-100">
              {formatAssetBalance(totalAmount, asset.decimals, true)}
            </dd>
          </div>

          {renderAccounts(asset.amounts)}

          {asset.id !== 0 && (
            <div className="flex items-center justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Creator</dt>
              <dd className="text-gray-900 dark:text-gray-100 max-w-[75%] xs:max-w-[80%] sm:max-w-[16rem] flex items-center justify-end pl-2">
                <UserThumbnail
                  address={asset.creator}
                  size="sm"
                  textColor="text-gray-900 group-hover:text-brand-500 dark:text-gray-100"
                  forceAddressFallback={asset.unitName === 'NFD'}
                  fallbackClassName="text-gray-900 truncate dark:text-gray-100"
                  wrapperClassName="min-w-0 flex items-center"
                  staleTime={Infinity}
                />
              </dd>
            </div>
          )}

          <div className="flex justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Unit Name</dt>
            <dd className="text-gray-900 dark:text-gray-100">{asset.unitName}</dd>
          </div>

          {asset.id !== 0 && (
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Asset ID</dt>
              <dd className="text-gray-900 dark:text-gray-100">{asset.id}</dd>
            </div>
          )}
        </dl>
      </div>
    )
  }

  const renderSendActions = () => {
    if (!canSendAsset && !canMoveToVault && !canMoveToDepositAccount) return null

    return (
      <>
        <div className="space-y-3">
          {canSendAsset && (
            <button
              type="button"
              className="flex items-center justify-center w-full gap-x-1.5 rounded-md bg-brand-600 px-3 py-2 sm:py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
              onClick={handleClickSendAsset}
            >
              <HiArrowUpRight className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Send
            </button>
          )}

          {canMoveToVault && (
            <button
              type="button"
              className="flex items-center justify-center w-full gap-x-1.5 rounded-md bg-white px-3 py-2 sm:py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:ring-0"
              onClick={handleClickMoveToVault}
            >
              Move to vault
            </button>
          )}

          {canMoveToDepositAccount && (
            <button
              type="button"
              className="flex items-center justify-center w-full gap-x-1.5 rounded-md bg-white px-3 py-2 sm:py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:ring-0"
              onClick={handleClickMoveToDepositAccount}
            >
              Move to deposit account
            </button>
          )}
        </div>
      </>
    )
  }

  const renderExplorerLinks = () => {
    return (
      <div>
        <div className="flex text-sm">
          <a
            href={lookupByAssetId(asset.id)}
            className="group inline-flex items-center text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
            target="_blank"
            rel="noreferrer"
          >
            <ExplorerIcon
              className="w-4 h-4 text-gray-400 group-hover:text-gray-500 dark:text-gray-600 dark:group-hover:text-gray-500"
              explorer={selectedExplorer}
            />
            <span className="ml-2">View on {selectedExplorer}</span>
          </a>
        </div>
        <div className="mt-4 flex text-sm">
          <a
            href={`https://explorer.perawallet.app/asset/${asset.id}`}
            className="group inline-flex items-center text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
            target="_blank"
            rel="noreferrer"
          >
            <IconPeraExplorer
              className="w-4 h-4 text-gray-400 group-hover:text-gray-500 dark:text-gray-600 dark:group-hover:text-gray-500"
              aria-hidden="true"
            />
            <span className="ml-2">View on Pera Explorer</span>
          </a>
        </div>
      </div>
    )
  }

  const renderSetFieldActions = () => {
    if (!canSetField) return null

    return (
      <div>
        <div className="flex text-sm">
          <button
            type="button"
            className="group inline-flex items-center text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
            onClick={() => handleClickSetField('avatar')}
          >
            <HiOutlineUserCircle
              className="h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-600 dark:group-hover:text-gray-500"
              aria-hidden="true"
            />
            <span className="ml-2">Set as avatar</span>
          </button>
        </div>
        <div className="mt-4 flex text-sm">
          <button
            type="button"
            className="group inline-flex items-center text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
            onClick={() => handleClickSetField('banner')}
          >
            <TbRectangle
              className="h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-600 dark:group-hover:text-gray-500"
              aria-hidden="true"
            />
            <span className="ml-2">Set as banner</span>
          </button>
        </div>
      </div>
    )
  }

  const renderViewProfile = () => {
    if (asset.unitName !== 'NFD') return null

    return (
      <div className="mt-4">
        <Link
          href={`/name/${asset.name}`}
          className="flex items-center justify-center w-full gap-x-1.5 rounded-md bg-white px-3 py-2 sm:py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:ring-0"
        >
          View profile
        </Link>
      </div>
    )
  }

  const renderImage = () => {
    if (!isNft && !asset.imageUrl) {
      return <TokenImage />
    }

    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.id.toString()}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="320px"
        fill
        options={{
          optimizeGifs: false,
          stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5'
        }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8 dark:bg-gray-900">
                    <div className="space-y-6 pb-16">
                      <div>
                        <div className="relative aspect-square block w-full overflow-hidden rounded-lg">
                          {renderImage()}
                        </div>
                        <div className="mt-5 flex items-start justify-between">
                          <div>
                            <h2 className="text-xl font-semibold leading-7 text-gray-900 dark:text-gray-100">
                              <span className="sr-only">Details for </span>
                              {asset.name}
                            </h2>
                          </div>
                        </div>
                      </div>
                      {renderInfo()}
                      {renderSendActions()}
                      {renderViewProfile()}
                      <div className="py-3 space-y-8">
                        {renderExplorerLinks()}
                        {renderSetFieldActions()}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
